.footer-container {
    position: absolute;

    width: 100%;
    height: auto; /* Height of the footer */
    background: linear-gradient(90deg, #7A07D9 0%, #007BFF 100%);;
    display: flex;
    flex: row;
    color: white;

}

.footer-container p {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1vw;
}

.footer-container h4 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1.3vw;
}

.footer-container .white-line {
    background-color: white;
    width: 20vw;
    height: 1px;
    margin-top: 2%;
    margin-bottom: 3%;

}

.footer-container .contact-info {
    margin: 2% 10% 2%;
    display: flex;
    flex-direction: column;
}

.footer-container .links {
    margin: 2% 0 2%;
    text-decoration: none;
    display: flex;
    flex-direction: column;

}

.footer-container .links-text {
    color: white;

}

.footer-container a {
    color: white;
}

.number::after {
    content: "General Telephone: (647) 237-9212";
}

.email::after {
    content: "General Email: info@aversa.ca";
}

.support-email::after {
    content: "Support Email: support@aversa.ca";
}

.footer-container .aversa-footer-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2%;
    margin-right: 2%;

}

.footer-container .aversa-footer-logo img {
    width: 70%;
    height: auto;
}

@media screen and (max-width: 1207px) {
    .footer-container p {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 2.2vw;

    }

    .footer-container h4 {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 2.6vw;
    }

    .footer-container .white-line {
        background-color: white;
        width: 20vw;
        height: 0.6px;
        margin-bottom: 10%;
    }

    .footer-container .contact-info {
        margin: 10% 10% 10%;
    }

    .footer-container .contact-info h4 {
        margin-bottom: 11%;
    }

    .footer-container .links {
        margin: 10% 0 10%;
    }

    .footer-container .links h4 {
        margin-bottom: 17%;

    }

    .footer-container .aversa-footer-logo {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: 5%;
        margin-right: 0%;
        margin-left: 70%;
        z-index: 0;

    }

    .footer-container .aversa-footer-logo img {
        width: 80%;
        height: auto;
    }
}
  
