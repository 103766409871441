.about-flex{
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    width: 100%;
}

.about-flex .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}

.about-flex .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
}
.about-flex .title {
    margin-top: 5%;
    margin-bottom: 0;
    position: relative;
}
.about-flex .title h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0 auto;
    display: block;
    margin-bottom: 0.5%;
    margin-top: 5%;
    font-size: 3.5vw;
    background-image: linear-gradient(90deg, #7A07D9 0%, #007BFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-flex .title .border-about {
    margin: 0 auto;
    display: block;
    width: 20vw;
}
#team-border {
    width: 50%;
    margin-bottom: 5%;
}
#mobile-border-about {
    display: none;
}
.about-flex .about-bg {
    margin-top: 1%;
    margin-bottom: 2%;
    width: 100%;
}
.about-flex .about-bg img {
    width: 100%;
}
.about-flex .about-section-1 {
    width: 100%;
    background: url('/public/images/transform-BG-1.webp');
    background-size: cover;
    vertical-align: middle;
    align-items: center;
}
#careers-two {
    background: linear-gradient(90deg, #F0E7FF 0%, #FFFFFF 100%);
    margin-top: 2%;
}
.about-flex .about-section-1 .caption {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 50%;
    height: 100%;
    position: relative;
    margin: auto;
}
.about-flex .images {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 50%;
    position: relative;
    margin-top: 5%;
    margin-bottom: 5%;
}
.about-flex .mobile-image-1 {
    display: none
}
.about-flex .about-section-1 .caption .first-caption {
    margin: auto;
    font-family: 'PT Sans';
    font-weight: 400;
    font-style: normal;
    font-size: 2.4vw;
    max-width: 85%;
    background-image: linear-gradient(180deg, #007BFF 0%, #7A07D9 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
 #bold {
    font-weight: bold;
}
.about-flex .about-section-1 .caption .mobile-first-caption {
    display: none;
}
.about-flex .about-section-1 .caption .text {
    margin: auto;
    margin-top: 5%;
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-size: 1.5vw;
    max-width: 80%;
    background: #7A07D9;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-flex .about-section-1 .images .left-img {
    width: 50%; 
    margin-left: 10%; 
    z-index: 1;
    border-radius: 5%;
}
.about-flex .about-section-1 .images .left-img-2 {
    width: 50%; 
    margin-left: 12.5%; 
    z-index: 1;
    border-radius: 5%;
}
.about-flex .about-section-1 .images .right-img {
    width: 50%;
    margin-left: 35%;
    z-index: 2;
    border-radius: 5%;
}
.about-flex .about-section-1 .images .right-img-2 {
    width: 50%;
    margin-left: 37.5%;
    z-index: 2;
    border-radius: 5%;
}
.about-flex .gradient-container {
    width: 100%;
    height: 5%;
    position: relative;
}
.about-flex .gradient-bar {
    width: 67%;
    display:block;
    height:4vh;
/* 
    background-image: linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -o-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -moz-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -webkit-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -ms-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%); */

    background-image: -webkit-gradient(
        linear,
        left bottom,
        right 0,
        color-stop(1, #E6F6FF),
        color-stop(0, #F0E7FF)   
    );
}


.about-flex-3{
    /* display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 10%;
    text-align: center; */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 2%;
    background-color:rgba(255,255,255,1);
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}
.about-flex-3 .aversa-team-header {
    text-align: center;
    font-size: 4vw;
    margin-bottom: 0%;
    margin-top: 3%;
    background-image: linear-gradient(90deg, #7A07D9 0%, #007BFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-flex-3 .people{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 5%;
}
/* .about-flex-3 .people-2{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 20px;
} */
.about-flex-3 .about-person img{
    width: 50%;
    height: 50%;
    margin-bottom: 5%;  
}
.about-flex-3 .about-person{
    display: flex;
    flex: 0 0 33.333333%;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 90%;
    font-family: "PT Sans";
    vertical-align: top;
    margin-bottom: 5%;
    color: #7A07D9;
}
.about-flex-3 .text{
    margin-top: 3%;
}

.about-flex-3 h1{
    font-family: 'Montserrat';
    font-size: 2vw;
    font-weight: 700;
}
.about-flex-3 h3{
    font-family: 'Montserrat';
    font-style: italic;
    font-size: 1.5vw;
    font-weight: 500;
}

@media screen and (max-width: 1500px) {
    .about-flex .title {
        margin-top: 7%;
    }
}

@media screen and (max-width: 1207px){
    .about-flex .title {
        margin-top: 9%;
    }
    .about-flex .about-section-1 .images .left-img {
        width: 55%;
    }
    .about-flex .about-section-1 .images .left-img-2 {
        width: 55%;
    }
    .about-flex .about-section-1 .images .right-img {
        width: 55%;
    }
    .about-flex .about-section-1 .images .right-img-2 {
        width: 55%;
    }
    
    .about-flex-3 .people{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        justify-content: space-evenly;
        margin-top: 20px;
        margin-bottom: 5%;
    }
    .about-flex-3 .about-person{
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        flex-direction: column;
        width: 90%;
        font-family: "PT Sans";
        vertical-align: top;
        margin-bottom: 5%;
    }
    .about-flex-3 h1{
        font-size: 2.2vw;
    }
    .about-flex-3 h3{
        font-size: 1.7vw;
    }
}

@media screen and (max-width: 900px) {
    .about-flex .title {
        margin-top: 11%;
    }
    .about-flex .about-section-1 .images .left-img {
        width: 60%;
    }
    .about-flex .about-section-1 .images .left-img-2 {
        width: 60%;
    }
    .about-flex .about-section-1 .images .right-img {
        width: 60%;
    }
    .about-flex .about-section-1 .images .right-img-2 {
        width: 60%;
    }
    .about-flex .about-section-1 .caption .first-caption {
        font-size: 2.6vw;
    }
    .about-flex-3 h1{
        font-size: 2.6vw;
    }
    .about-flex-3 h3{
        font-size: 2vw;
    }
}
@media screen and (max-width: 800px) {
    .about-flex .title {
        margin-top: 12.5%;
    }
}
@media screen and (max-width: 750px) {
    .about-flex .title {
        margin-top: 14%;
    }
    .about-flex .title h1 {
        font-size: 3.9vw;
    }
    
    .about-flex .title .border-about {
        margin: 0 auto;
        display: block;
        width: 23vw;
    }
    .about-flex-3 .aversa-team-header {
        font-size: 4.5vw;
        margin-top: 4%;
    }
    #team-border {
        width: 55%;
        margin-bottom: 5%;
    }
}
@media screen and (max-width: 650px) {
    .about-flex .title {
        margin-top: 16%;
    }
    .about-flex .title h1 {
        font-size: 4.4vw;
    }
    
    .about-flex .title .border-about {
        width: 25vw;
    }
    .about-flex .about-section-1 .images .left-img {
        width: 65%;
    }
    .about-flex .about-section-1 .images .left-img-2 {
        width: 65%;
    }
    .about-flex .about-section-1 .images .right-img {
        width: 65%;
    }
    .about-flex .about-section-1 .images .right-img-2 {
        width: 65%;
    }
    .about-flex .about-section-1 .caption .first-caption {
        font-size: 2.8vw;
    }
    .about-flex-3 h1{
        font-size: 2.8vw;
    }
    .about-flex-3 h3{
        font-size: 2.2vw;
    }
    #caption-two {
        margin-left: 15%;
    }
}
@media screen and (max-width: 550px) {
    .about-flex .row {
        flex-flow: column wrap;
    }

    .about-flex .title {
        margin-top: 18%;
    }
    .about-flex .title h1 {
        font-size: 6vw;
    }
    .about-flex .title .border-about {
        width: 33vw;
    }
    .about-flex .about-section-1 .caption {
        max-width: 85%;
        margin-top: 3%;
    }
    .about-flex .about-section-1 .caption .first-caption {
        text-align: center;
        font-size: 4.5vw;
        margin-top: 2%;
    }
 
    #mobile-border-about {
        margin: 0 auto;
        display: block;
        width: 75vw;
        margin-top: 1%;
    }
    .about-flex .images {
        display: none
    }
    .about-flex .mobile-image-1 {
        width: 100%;
        display: unset;
        margin: auto;
        margin-top: 4%;
        margin-bottom: 4%;
        
    }
    .about-flex .mobile-image-1 img {
        margin: 0 auto;
        display: block;
        width: 90%;
        border-radius: 7%;
    }
    .about-flex .about-section-1 .caption .text {
        margin: auto;
        margin-top: 3%;
        font-family: 'Montserrat';
        font-weight: 400;
        font-style: normal;
        font-size: 3.3vw;
        max-width: 100%;
        text-align: center;
    }
    .about-flex-3 .aversa-team-header {
        font-size: 6vw;
        margin-top: 6%;
    }
    #team-border {
        width: 73%;
        margin-bottom: 5%;
    }
    .about-flex-3 .about-person img{
        width: 50%;
        height: 50%;
        margin-bottom: 5%;  
    }
    .about-flex-3 .about-person{
        display: flex;
        flex: 0 0 90%;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        width: 90%;
        font-family: "PT Sans";
        vertical-align: top;
        margin-bottom: 5%;
        color: #7A07D9;
    }
    .about-flex-3 h1{
        font-size: 4vw;
    }
    .about-flex-3 h3{
        font-size: 3.4vw;
    }
    #caption-two {
        margin: auto;
    }
}

@media screen and (max-width: 470px) {
    .about-flex .title {
        margin-top: 20%;
    }
    .about-flex .title h1 {
        font-size: 6vw;
    }
    .about-flex-3 h1{
        font-size: 4.4vw;
    }
    .about-flex-3 h3{
        font-size: 3.7vw;
    }
}
@media screen and (max-width: 420px) {
    .about-flex .title {
        margin-top: 24%;
    }
    #team-border {
        margin-bottom: 3%;
    }
}
@media screen and (max-width: 360px) {
    .about-flex .title {
        margin-top: 28%;
    }
    .about-flex-3 h1{
        font-size: 4.7vw;
    }
    .about-flex-3 h3{
        font-size: 4vw;
    }
}

@media screen and (max-width: 320px) {
    .about-flex .title {
        margin-top: 34%;
    }
}

