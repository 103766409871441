.careers-flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat';
    color: #701833;
    width: 100%;
    position: relative;
}
.careers-flex .underline {
    border-bottom: 4px solid currentColor;
}
.careers-flex .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}

.careers-flex .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;
}
.careers-flex .title {
    margin-top: 5%;
    margin-bottom: 0;
    position: relative;
}
.careers-flex .title h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    margin: 0 auto;
    display: block;
    margin-bottom: 0.5%;
    margin-top: 5%;
    font-size: 3.5vw;
    background-image: linear-gradient(90deg, #7A07D9 0%, #007BFF 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.careers-flex .title .border-careers {
    margin: 0 auto;
    display: block;
    width: 20vw;
}
#mobile-border {
    display: none;
}

.careers-flex .careers-section-1 {
    width: 100%;
    background: url('/public/images/transform-BG-1.webp');
    background-size: cover;
    vertical-align: middle;
    align-items: center;
}
#careers-two {
    margin-top: 2%;
    background: url('/public/images/transform-BG-1.webp');
    background-size: cover;
}
.careers-flex  .careers-section-1 .caption {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 50%;
    height: 100%;
    position: relative;
    margin: auto;
}
.careers-flex .images {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 50%;
    position: relative;
    margin-top: 5%;
    margin-bottom: 5%;
}
.careers-flex .mobile-image-1 {
    display: none
}
.careers-flex .careers-section-1 .caption .first-caption {
    margin: auto;
    font-family: 'Montserrat';
    font-weight: 700;
    font-style: normal;
    font-size: 2.83vw;
    max-width: 85%;
    background-image: linear-gradient(180deg, #007BFF 0%, #7A07D9 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.careers-flex .careers-section-1 .caption .mobile-first-caption {
    display: none;
}
.careers-flex .careers-section-1 .caption .text {
    margin: auto;
    margin-top: 5%;
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    font-size: 1.5vw;
    max-width: 80%;
    background: #7A07D9;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.careers-flex .careers-section-1 .images .left-img {
    width: 55%; 
    margin-left: 5%; 
    z-index: 1;
    border-radius: 5%;
}
.careers-flex .careers-section-1 .images .left-img-2 {
    width: 55%; 
    margin-left: 15%; 
    z-index: 1;
    border-radius: 5%;
}
.careers-flex .careers-section-1 .images .right-img {
    width: 55%;
    margin-left: 30%;
    z-index: 2;
    border-radius: 5%;
}
.careers-flex .careers-section-1 .images .right-img-2 {
    width: 55%;
    margin-left: 40%;
    z-index: 2;
    border-radius: 5%;
}
.careers-flex .gradient-container {
    width: 100%;
    height: 5%;
    position: relative;
}
.careers-flex .gradient-bar {
    width: 67%;
    display:block;
    height:4vh;
/* 
    background-image: linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -o-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -moz-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -webkit-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%);
    background-image: -ms-linear-gradient(rgba(246, 228, 222, 0.6) 0%, rgba(112, 24, 51, 0.6) 100%); */

    background-image: -webkit-gradient(
        linear,
        left bottom,
        right 0,
        color-stop(1, #E6F6FF),
        color-stop(0, #F0E7FF)   
    );
}

.careers-flex .header {
    border-radius: 40px;
    border: 5px solid #701833;
    padding: 25px 50px;
    margin-top: 5%;
    max-width: 80%;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23A0A0A0'/%3E%3Cstop offset='1' stop-color='%23FFFFFF'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%236E7169' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%236E7169' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.05'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
}
.careers-flex .header h1 {
    font-size: 3vw;
}


.careers-flex .flex-box-banner {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-top: 2%;
}
.careers-flex .flex-box-banner .banner-image {
}
.careers-flex .flex-box-banner .mobile-banner-image {
    display: none;
}
.careers-flex .flex-box-banner .banner-image img {
    width: 100%;
    height: auto;
    display: block;
}
.careers-flex .flex-box-banner .first-header{
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.careers-flex .flex-box-banner .second-header{
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.careers-flex .flex-box-banner .third-header{
    position: absolute;
    top: 25%;
    left: 75%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    font-weight: bold;
}
.careers-flex .flex-box-banner .three-banner-caption {
    position: absolute;
    top: 70%;
    left: 57.5%;
    transform: translate( -50%, -50% );
    text-align: center;
    color: white;
    width: 75%;
}
.careers-flex .flex-box-banner h3 {
    font-family: 'Montserrat';
    color: white;
    font-weight: bold;
    font-size: 2.75vw;
}
.careers-flex .flex-box-banner p {
    font-family: 'Montserrat';
    color: white;
    font-size: 1.6vw;
    max-width: 80%;
}


.careers-flex .flex-box-3 {
    width: 100%;
    height: auto;
    background-color: #362077;
    background-image: linear-gradient(45deg, #362077, #573ea0);
    color: white;
    text-align: center;
    margin-top: 10%;
    font-family: 'Montserrat';
}

.careers-flex .flex-box-3 h3 {
    margin-top: 80px;
    font-weight: bolder;
}

.careers-flex .flex-box-3 h1 {
    font-size: 4.5vw;
    margin: 10px 300px 30px;

}

.careers-flex .flex-box-3 p {
    font-size: 1.5vw;
    margin: 0px 350px 80px;
}

.careers-flex .flex-box-4 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    background: url("/public/images/careers-background-1.webp");
    background-size: cover;
}

.careers-flex .flex-box-4 .listing {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 15px 1px #cfcdcd8a;
    color: #7a07d9;
    background-color: white;
    border-radius: 10px;
    width: 30%;
    height: auto;
    margin: 2.5% 1% 2.5%;
    background: url("/public/images/careers-background-1.webp");

}


.careers-flex .flex-box-4 .listing h1 {

    font-weight: bold;
    font-size: 2.6vw;
    margin: 25% 0% 5%;
}

.careers-flex .flex-box-4 .listing h3 {
    font-size: 1.4vw;


}

.careers-flex .flex-box-4 .listing a {
    margin: 2% 0% 25%;
    font-size: 1.2vw;
}

.careers-flex .flex-box-4 .listing a:hover {
    text-decoration: underline;
}

.careers-flex .flex-box-4 .listing:hover {
    
    background-image: linear-gradient(45deg, #7a07d9, #5b90f4);
    color: white;
    box-shadow: 0px 2px 30px 2px #cfcdcd;
    cursor: pointer;
}

@media screen and (max-width: 1500px) {
    .careers-flex .title {
        margin-top: 8%;
    }
}
@media screen and (max-width: 1207px) {

    .careers-flex .flex-box-1 .careers-main-image img {
        display: none;
    }

    .careers-flex .flex-box-1 .careers-main-image-mobile img {
        display: block;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
    }

    .careers-flex .flex-box-1 .first-image-group {
        float: right;
        margin-top: 10%;
        margin-right: 5%;
        text-align: right;
        width: 100%;
        align-items: center;
    }

    .careers-flex .flex-box-1 .first-header {
        width: 100%;
        margin-right: 8%;
        margin-top: 12%;
        margin-left: 5%;
    }

    /* .careers-flex .flex-box-1 .first-image-group .first-image{
        margin-right: 30%;
        width: 20%;
        height: auto;
    }
    .careers-flex .flex-box-1 .first-image-group .second-image{
        float: right;
        width: 20%;
        height: auto;
    }
    .careers-flex .flex-box-1 .first-image-group .first-image .img {
        width: 20%;
        height: auto;
    }
    .careers-flex .flex-box-1 .first-image-group .second-image .img {
        width: 20%;
        height: auto;
    } */
    .careers-flex .flex-box-1 .first-image-group .first-image {
        margin-right: 30%;
    }

    .careers-flex .flex-box-1 h1 {
        font-size: calc(2em + 0.5vmin);
        
    }

   
    .careers-flex .flex-box-2 h1 {
        
    }

    .careers-flex .flex-box-2 .first-image-group-2 {
        float: right;
        margin-top: 10%;
        margin-left: 5%;
        text-align: right;
        width: 100%;
        align-items: center;
    }

    .careers-flex .flex-box-2 .second-header {
        width: 100%;
        margin-left: 8%;
        margin-top: 12%;
        margin-right: 5%;
    }

    .careers-flex .flex-box-2 .first-image-group-2 .second-image-2 {
        margin-left: 30%;
    }

    .careers-flex .flex-box-2 h1 {
        font-size: calc(2em + 0.5vmin);
        
    }


    .careers-flex .flex-box-3 {
       
        margin-top: 20%;
        
    }
    
    .careers-flex .flex-box-3 h3 {
        margin-top: 10%;
        font-weight: bolder;
    }
    

    
    .careers-flex .flex-box-3 h1 {
        font-size: 6vw;
        margin: 10px 10% 30px;
    
    }
    
    .careers-flex .flex-box-3 p {
        font-size: 4vw;
        margin: 0% 10% 10%;
    }
/* 
    .careers-flex .flex-box-4 {
        flex-direction: column;
    }

    .careers-flex .flex-box-4 .listing {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 15px 2px #cfcdcd;
    color: #7a07d9;
    border-radius: 10px;
    width: 80%;
   
    margin: 5% 10% 5%;
}
.careers-flex .flex-box-4 .listing h1 {

    font-weight: bold;
    font-size: 9.5vw;
    margin: 25% 0% 5%;
}

.careers-flex .flex-box-4 .listing h3 {
    font-size: 5vw;


}

.careers-flex .flex-box-4 .listing a {
    margin: 2% 0% 25%;
    font-size: 3vw;
} */
.careers-flex .flex-box-4 .listing a {
    font-size: 1.8vw;
}
}

@media screen and (max-width: 1100px) {
    .careers-flex .careers-section-1 .caption .text {
        font-size: 1.7vw;
        max-width: 80%;
    }
    .careers-flex .careers-section-1 .caption .first-caption {
        max-width: 90%;
    }

    /* .careers-flex .flex-box-1 .first-image-group{
        max-width: 30%;
    } */
    /* .careers-flex .flex-box-1 .first-image-group .first-image img {
        width: 88%;        
    }
    .careers-flex .flex-box-1 .first-image-group .second-image img {
        width: 82%
        
    }
    .careers-flex .flex-box-2 .first-image-group-2 .first-image-2 img {
        width: 88%
        
    }
    .careers-flex .flex-box-1 .first-image-group-2 .second-image-2 img {
        width: 80%
        
    } */
    .careers-flex .flex-box-banner h3 {
        font-size: calc(1.4em + 0.5vmin);
    }
    .careers-flex .flex-box-banner p {
        font-size: calc(1.2em + 0.4vmin);
    }
}

@media screen and (max-width: 1000px) {
    .careers-flex .title {
        margin-top: 10%;
    }
    .careers-flex .title h1 {
        font-size: 4vw;
    }
    .careers-flex .flex-box-banner p {
        font-size: 1.75vw;
    }
    .careers-flex .professions-section-1 .caption .text {
        font-size: 1.65vw;
    }
    .careers-flex .flex-box-1 .first-header {
        margin-top: 14.5%;
    }
    .careers-flex .flex-box-1 .first-image-group {
        margin-top: 12.5%;
        margin-right: 12%;
    }
    .careers-flex .flex-box-1 .first-image-group .first-image {
        max-width: 80%;        
    }
    .careers-flex .flex-box-1 .first-image-group .second-image {
        max-width: 80%
        
    }
    .careers-flex .flex-box-2 .first-image-group-2 .first-image-2 {
        max-width: 80%
        
    }
    .careers-flex .flex-box-2 .first-image-group-2 .second-image-2 {
        max-width: 80%
        
    }

    .careers-flex .flex-box-banner p {
        font-size: calc(1em + 0.4vmin);
    }
}

@media screen and (max-width: 900px) {
    .careers-flex .careers-section-1 .caption .text {
        font-size: 1.8vw;
        max-width: 80%;
    }
}

@media screen and (max-width: 800px) {
    .careers-flex .title {
        margin-top: 14%;
    }
    .careers-flex .flex-box-banner p {
        font-size: 1.9vw;
    }
    .careers-flex .careers-section-1 .caption .text {
        font-size: 1.9vw;
    }
}

@media screen and (max-width: 750px) {
    .careers-flex .title {
        margin-top: 15%;
    }
    .careers-flex .title h1 {
        font-size: 4,3vw;
    }
    .careers-flex .title .border-careers {
        margin: 0 auto;
        display: block;
        width: 22vw;
    }
    .careers-flex .flex-box-banner p {
        font-size: 2.1vw;
    }
    .careers-flex .careers-section-1 .caption .text {
        font-size: 1.95vw;
    }

    .careers-flex .flex-box-1 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        width: 100%;
        height: auto;
    }

    .careers-flex .flex-box-1 h1 {
        text-align: center;
    }

    .careers-flex .flex-box-1 .first-image-group {
        display: none;
    }
    .careers-flex .flex-box-1 .first-image-mobile {
        display: block;
        width: 100%;
    }
    .careers-flex .flex-box-1 .first-header {
        display: flex;
        flex: 0 0 100%;
        margin: 0;
        text-align: center;
        margin-bottom: 5%;
    }

    .careers-flex .flex-box-2 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        width: 100%;
        height: auto;
    }

    .careers-flex .flex-box-2 h1 {
        text-align: center;
        
    }

    .careers-flex .flex-box-2 p {
        text-align: center;
    }

    .careers-flex .flex-box-2 .first-image-group-2 {
        display: none;
    }
    .careers-flex .flex-box-2 .second-image-mobile {
        display: block;
        width: 100%;
    }
    .careers-flex .flex-box-2 .second-header {
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
        margin: 0;
        text-align: center;
    }

    .careers-flex .flex-box-banner h3 {
        font-size: calc(1.1em + 0.5vmin);
    }

    
    .careers-flex .flex-box-4 {
        flex-direction: column;
    }

    .careers-flex .flex-box-4 .listing {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 2px 15px 2px #cfcdcd;
        color: #7a07d9;
        border-radius: 10px;
        width: 80%;
    
        margin: 5% 10% 5%;
    }
.careers-flex .flex-box-4 .listing h1 {

    font-weight: bold;
    font-size: 9.5vw;
    margin: 25% 0% 5%;
}

.careers-flex .flex-box-4 .listing h3 {
    font-size: 5vw;


}

.careers-flex .flex-box-4 .listing a {
    margin: 2% 0% 25%;
    font-size: 3vw;
}
}

@media screen and (max-width: 700px) {
    .careers-flex .careers-section-1 .caption .first-caption {
        margin: auto;
        font-family: 'Montserrat';
        font-weight: 700;
        font-style: normal;
        font-size: 3vw;
        max-width: 92%;
        background-image: linear-gradient(180deg, #007BFF 0%, #7A07D9 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .careers-flex .careers-section-1 .caption .text {
        font-size: 2.1vw;
        max-width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .careers-flex .title {
        margin-top: 16%;
    }
}

@media screen and (max-width: 550px) {
    .careers-flex .row {
        flex-flow: column wrap;
    }

    .careers-flex .title {
        margin-top: 18%;
    }
    .careers-flex .title h1 {
        font-size: 6vw;
    }
    .careers-flex .title .border-careers {
        width: 33vw;
    }
    .careers-flex .careers-section-1 .caption {
        max-width: 85%;
        margin-top: 3%;
    }
    .careers-flex .careers-section-1 .caption .first-caption {
        text-align: center;
        font-size: 4.5vw;
        margin-top: 2%;
    }
 
    #mobile-border {
        margin: 0 auto;
        display: block;
        width: 75vw;
        margin-top: 1%;
    }
    .careers-flex .images {
        display: none
    }
    .careers-flex .mobile-image-1 {
        display: unset;
        margin: auto;
        margin-top: 4%;
        margin-bottom: 4%;
        max-width: 100%;
    }
    .careers-flex .mobile-image-1 img {
        width: 100%;
        border-radius: 7%;
    }
    .careers-flex .careers-section-1 .caption .text {
        margin: auto;
        margin-top: 3%;
        font-family: 'Montserrat';
        font-weight: 400;
        font-style: normal;
        font-size: 3.3vw;
        max-width: 100%;
        text-align: center;
    }

    .careers-flex .flex-box-banner .banner-image {
        display: none;
    }
   
    .careers-flex .flex-box-banner .mobile-banner-image {
        display:initial;
    }
    .careers-flex .flex-box-banner .mobile-banner-image img {
        width: 100%;
        height: auto;
        display: block;
    }

    .careers-flex .flex-box-banner .first-header{
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate( -50%, -50% );
        text-align: center;
        color: white;
        font-weight: bold;
    }
    .careers-flex .flex-box-banner .second-header{
        position: absolute;
        top: 85%;
        left: 25%;
        transform: translate( -50%, -50% );
        text-align: center;
        color: white;
        font-weight: bold;
    }
    .careers-flex .flex-box-banner .third-header{
        position: absolute;
        top: 85%;
        left: 75%;
        transform: translate( -50%, -50% );
        text-align: center;
        color: white;
        font-weight: bold;
    }
    .careers-flex .flex-box-banner .three-banner-caption{
        position: absolute;
        top: 50%;
        left: 57%;
        transform: translate( -50%, -50% );
        text-align: center;
        color: white;
    }
    .careers-flex .flex-box-banner h3 {
        font-size: 6vw;
    }
    .careers-flex .flex-box-banner p {
        font-size: 4vw;
    }
    .careers-flex .flex-box-4 .listing a {
        font-size: 4vw;
    }
}

@media screen and (max-width: 500px) {
    .careers-flex .title {
        margin-top: 21%;
    }
    .careers-flex .title h1 {
        font-size: 6vw;
    }
}

@media screen and (max-width: 450px) {
    .careers-flex .title {
        margin-top: 24%;
    }
    .careers-flex .title h1 {
        font-size: 6.3vw;
    }

}

@media screen and (max-width: 400px) {
    .careers-flex .title {
        margin-top: 26%;
    }
    .careers-flex .mobile-image-1 img {
        margin: 0 auto;
        display: block;
        width: 90%;
    }
    .careers-flex .careers-section-1 .caption .first-caption {
        text-align: center;
        font-size: 4.75vw;
        margin-top: 2%;
    }
    .careers-flex .careers-section-1 .caption .text {
        margin: auto;
        margin-top: 3%;
        font-family: 'Montserrat';
        font-weight: 400;
        font-style: normal;
        font-size: 3.5vw;
        max-width: 100%;
        text-align: center;
    }

}

@media screen and (max-width: 350px) {
    .careers-flex .title {
        margin-top: 30%;
    }
}

@media screen and (max-width: 300px) {
    .careers-flex .title {
        margin-top: 33%;
    }
}
