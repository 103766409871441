.products-flex{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #F6E4DE;
}

.products-flex .flex-box-1-products {
    width: 100%;
    height: auto;

}

.products-flex .flex-box-1-products .saule-main-img img {
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}
.products-flex .flex-box-1-products .saule-main-img-mobile img {
   display: none;
}
.products-flex .flex-box-1-products .text{
    margin-top: -27%;
 
    width: 95%;
    margin-left: 5%;
}
.products-flex .flex-box-1-products .text h1{
    font-size: 4vw;
    font-weight: bolder;
    color: #701833;
}
.products-flex .flex-box-1-products .text p{
    color: #e33637;
    font-size: 1.7vw;
}
.products-flex .flex-box-1-products button {
    
    
    border-radius: 40px;
    background-color: #701833;
    color: white;
    border: none;
    text-decoration: none;
    padding: 1vw;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
}

.products-flex .flex-box-1-products button:hover {
    
    
    
    background-color: #e33637;
    color: #ffffff;
    border: black;
    text-decoration: none;
    
    transition-duration: 0.2s;
}

.products-flex .flex-box-2-products {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 10%;

}

.products-flex .flex-box-2-products .titles h1{
    font-family: "PT Sans";
    font-weight: bolder;
    color: #701833;
}

.products-flex .flex-box-2-products .titles p{
    font-family: "PT Sans";
    color: #e33637;
    font-size: 1.5vw;
}

.products-flex .flex-box-2-products .features{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 20px;

}

.products-flex .flex-box-2-products .featureitem{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
    font-family: "PT Sans";
    vertical-align: top;
}
#itemone{
    margin-top: 3%;
}
.products-flex .flex-box-2-products .featureitem p{
    margin: 20px 30px 40px;
}

.products-flex .flex-box-2-products .featureitem .cool{
    color:#701833;
    font-weight: bolder;
}

.products-flex .flex-box-3-products{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 70px;
    background-image: linear-gradient(to right, #ffffff , #f3c5c61e, #f8cfcf20);
    
}

.products-flex .flex-box-3-products img{
    width: 100%;
    height: auto;
    background-color: white;
    z-index: 23;
}
.products-flex .flex-box-3-products .text{
    margin: 80px 100px 40px;
    font-family: "PT Sans";
    
    width: 50%;
}
.products-flex .flex-box-3-products h1{
    color: #701833;
    font-weight: bold;
   
}
.products-flex .flex-box-3-products h3{
    margin: 60px 0px 0px;
    color: #e33637;
}
.products-flex .flex-box-3-products p{
    margin: 40px 0px 40px;
}

.products-flex .saule-video-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 24% 0 0;
}
.products-flex .saule-video-container h1{
color: #701833;
font-weight: bold;
font-size: 3vw;

}

.products-flex .saule-video-container p{
    color: #e33637;
    font-size: 1.5vw;
}
.products-flex .saule-video-container video{
    margin-top: 2%;
    
}
.products-flex .saule-video-container .saule-video video{
    width: 75vw;
    height: auto;
}
.products-flex .saule-video-container .saule-video-mobile video{
    width: 100vw;
    height: auto;
}
.products-flex .saule-video-container .saule-video-mobile{
    display: none;
}
.products-flex .flex-box-4-products {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-top: 7%;

}

.products-flex .flex-box-4-products .titles h1{
    font-family: "PT Sans";
    color: #701833;
    font-weight: bolder;
}

.products-flex .flex-box-4-products .titles h3{
    font-family: "PT Sans";
    color: #e33637;
    margin: 30px 420px 20px;
    font-size: 1.5vw;
    
}

.products-flex .flex-box-4-products .features{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin-top: 20px;

}
#itemone-product{
    margin-top: 2%;
}
#itemtwo-product{
    margin-top: 0.5%;
}
#itemthree-product{
    margin-top: 4.5%;
}
.products-flex .flex-box-4-products .featureitem{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    font-family: "PT Sans";
    vertical-align: top;
}

.products-flex .flex-box-4-products .featureitem h3{
    color: #e33637;
    font-weight: bolder;
    font-size: 25px;
}
.products-flex .flex-box-4-products .featureitem p{
    margin: 20px 60px 40px;
   
}
.products-flex .flex-box-5-products{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 80px 0px 0; 
    background-image: url("phonefinger.jpg");
    padding-bottom: 25%;
    min-height: 100%;
    
    background-size: cover; 
}


 .popups-form .submit-button {
     margin: 20% 0% 10%;
     border-radius: 40px;

     background-color: #701833;
     color: white;
     border: none;
     text-decoration: none;
     padding: 1vw;
     cursor: pointer;
     transition-duration: 0.2s;
     font-family: 'Montserrat';
     min-width: 100px;
}

 .popups-form .submit-button:hover {
     background-color: #e33637;
     color: #ffffff;
     border: black;
     text-decoration: none;

     transition-duration: 0.2s;
 }

.popups-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.popups-form h1 {
    color: #701833;
    font-family: 'Montserrat';
    font-size: 1.6vw;
    margin: 10% 10% 2%;
}

.popups-form p {
    color: #2a3455;
    margin: 0 30% 5%;
    font-size: 1.4vw;


}

.popups-form .form-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;

}
.popups-form form{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}


.popups-form .form-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin: 0% 4% 2%;
    color: #e33637;
    width: 40%;
}
.popups-form .form-box input{
    width: 100%;
}
.popups-form .form-flex{
    margin-bottom: 6%;
}

.popups-form .close-popup {
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2%;
    margin-right: 2%;
}
.popups-form .submit-container{
    width: 100%;
    height: auto;
    align-items: center;
}

.products-flex .flex-box-5-products .text{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 200px 0px 0px;
    text-align: center;
    width: 40%;
}
.products-flex .flex-box-5-products h1{
    font-family: "PT Sans";
    color:#701833;
    font-weight: bold;
    font-size: 3vw;
    margin-bottom: 4%;
}
.products-flex .flex-box-5-products p{
    color:#e33637;
    font-size: 1.5vw;
    font-family: "PT Sans";
}

.products-flex .flex-box-5-products button {
    
    margin: 20% 0% 10%;
    border-radius: 40px;
  
    background-color: #701833;
    color: white;
    border: none;
    text-decoration: none;
    padding: 1vw;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
}

.products-flex .flex-box-5-products button:hover {
    
    background-color: #e33637;
    color: #ffffff;
    border: black;
    text-decoration: none;
    transition-duration: 0.2s;
}

@media screen and (max-width: 1024px) and (min-width: 1020px){
    .products-flex .flex-box-1-products .saule-main-img-mobile img {
       margin-top: -3%;
    }
    .products-flex .flex-box-1-products .text{
       margin-bottom: 80px;
    }
}
@media screen and (max-width: 912px) and (min-width: 910px){
    .products-flex .flex-box-1-products .saule-main-img-mobile img {
       margin-top: -5%;
    }
    .products-flex .flex-box-1-products .text{
       margin-bottom: 100px;
    }
    
}


@media screen and (max-width: 1207px) {
    .products-flex .flex-box-1-products .saule-main-img img {
        display: none;
       
    }
    
    .products-flex .flex-box-1-products .saule-main-img-mobile img {
        display: block;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
       

    }
    .products-flex .flex-box-1-products .text{
        margin-top: -45%;
        margin-left: 5%;
        width: 65%;
    }
    .products-flex .flex-box-1-products .text h1{
        font-size: 7vw;
        font-weight: bolder;
        color: #701833;
    }
    .products-flex .flex-box-1-products .text p{
        color: #e33637;
        font-size: 3.5vw;
        width: 65%;
    }
    .products-flex .flex-box-1-products button {
    
    
        border-radius: 40px;
        background-color: #701833;
        color: white;
        border: none;
        text-decoration: none;
        padding: 1.3vw;
        cursor: pointer;
        font-size: 2.5vw;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
    }
    .products-flex .flex-box-2-products {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-top: 15%;
    
    }
    .products-flex .flex-box-2-products .titles h1{
        font-family: "PT Sans";
        font-weight: bolder;
        color: #701833;
        font-size: 7vw;
    }
    
    .products-flex .flex-box-2-products .titles p{
        font-family: "PT Sans";
        color: #e33637;
        font-size: 3.5vw; 
        margin: 20px 50px 0px;
    }

    .products-flex .flex-box-1-products {
        margin-top: 10%;
    }
    
    .products-flex .flex-box-2-products .features{
       
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    
    }
    .products-flex .flex-box-2-products .featureitem{
        width: 70%;
    }
    .products-flex .flex-box-2-products .featureitem p{
        margin: 20px 0px 40px;
    }
    .products-flex .flex-box-3-products{
      
        flex-direction: column; 
        
         
    }
    .products-flex .flex-box-3-products .image{
        width: 100%;
        height: auto;
    }
    .products-flex .flex-box-3-products img{
        width: 100%;
        height: auto;
    }
    .products-flex .flex-box-3-products .text{
        margin: 50px 10% 0px;
        width: 80%;
       
       
        
    }
    .products-flex .flex-box-3-products h1{
        font-size: 7vw;
    }
    .products-flex .flex-box-3-products h3{
       font-size: 3.2vw;
  margin-top: 15px;
    }
    .products-flex .flex-box-3-products p{
        margin: 30px 0px 40px;
        font-size: 3vw;
    }
    .products-flex .saule-video-container h1{
        font-size: 6vw;
        }
        
        .products-flex .saule-video-container p{
            color: #e33637;
            font-size: 3vw;
        }
    .products-flex .saule-video-container .saule-video{
        display: none;
        
    }
    .products-flex .saule-video-container .saule-video-mobile{
        display: flex;
    }

   
   
    .products-flex .flex-box-3-products{
      
        flex-direction: column;
         
    }
    .products-flex .flex-box-4-products .features{
       
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    
    }
    .products-flex .flex-box-4-products .titles h1{
      font-size: 5vw;
    }
    
    .products-flex .flex-box-4-products .titles h3{
        font-size: 4vw;
        margin: 0px 20px 0px;
        
    }
    .products-flex .flex-box-4-products .featureitem{
        width: 70%;
    }
    .products-flex .flex-box-4-products .featureitem p{
        margin: 10px 0px 20px;
        font-size: 3vw;
    }
    .products-flex .flex-box-4-products .featureitem h3{
        font-size: 4vw;
    }

    .products-flex .flex-box-5-products{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 80px 0px 0;
        background-image: url("phonefingermobile.jpg");
        padding-bottom: 20%;
        
        background-size: cover; 
        
    }
    .products-flex .flex-box-5-products .text{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 120px 0px 0px;
        text-align: center;
        width: 100%;
    }
    .products-flex .flex-box-5-products h1{
        font-family: "PT Sans";
        color:#701833;
        font-size: 6vw;
        font-weight: bold;
    }
    .products-flex .flex-box-5-products p{
        color:#e33637;
        font-family: "PT Sans";
        font-size: 3vw;
        font-weight: bold;
    }
    
    .products-flex .flex-box-5-products button {
        
        margin: 30px 0px 40%;
        border-radius: 40px;
        background-color: #701833;
        font-size: 3vw;
        color: white;
        border: none;
        text-decoration: none;
        padding: 2vw;
        cursor: pointer;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
    }
    .popups-form h1 {
      
        font-family: 'Montserrat';
        font-size: 5vw;
        margin: 10% 10% 3%;
    }
    .popups-form p{
        font-size: 2.3vw;
        margin: 0 20% 5%;
    }
    .popups-form .form-box {
        margin: 5% 0 5%;
        width: 100%;
    }

    .popups-form .form-flex {
        display: flex;
        flex-direction: column;
    }
}
@media screen and (min-width: 1600px){

    #itemone-product{
        margin-top: 2%;
    }
    #itemtwo-product{
        margin-top: 0.8%;
    }
    #itemthree-product{
        margin-top: 2%;
    }

.products-flex .flex-box-5-products button {
    
    margin: 30px 0px 350px;
    
}
.products-flex .flex-box-5-products{
       
    padding-bottom: 10%;
    
 
    
}
}
@media screen and (max-width: 1024px) and (min-width: 540px){
    .products-flex .flex-box-5-products{
       
        padding-bottom: 40%;
        
     
        
    }
}
