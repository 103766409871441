.home-flex-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat';
    background-image: linear-gradient(#E5F6FF, #F0E7FF);
    background: white;
    
}

.flex-box-2 {
    width: 100%;
    height: auto;
    background: url("/public/images/landing-background-1.webp");
    background: white;
    background-size: cover;
}

.home-flex-box .flex-box-2 .text {
    width: 100%;

    justify-content: center;
    text-align: center;
    z-index: 10;
    margin-top: 7%;
    font-family: 'Montserrat';
    font-weight: bold;
    background-image: linear-gradient(45deg, #7a07d9, #5b90f4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
   

}

.home-flex-box .flex-box-2 .text h1 {
    font-family: 'Montserrat';
    color: #50058e;
    font-weight: bold;
    font-size: 4vw;
    width: 100%;

}

.flex-box-2 .home-main-img img {
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
}

.flex-box-2 .home-main-img-mobile img {
    display: none;
}



.Slider{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
    text-align: center;
    height: auto;
    margin: auto;
    margin-top: 5%;
}
 
.Slider .home-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: left;
    background-image: linear-gradient(#007BFF, white);
    border-radius: 12%;
    width: 90%;
    height: clamp(200px, 30vw, 600px);
    margin-left: 5%;
    cursor: pointer;
}
.Slider .outer .home-card:hover {
    background-image: linear-gradient(#7A07D9, white);
    transition-duration: 0.3s;
}
 .Slider .home-card h1 {
    font-weight: bolder;
    font-size: 2vw;
    margin: 2% 5% 2%;
}
.Slider .home-card .field{
    margin: 10% 5% 0%;
}
.Slider .home-card p{
    font-size: 1.4vw;
}
.Slider .home-card .img-box{
    display: flex;
    max-width: 100%;
    overflow: hidden;
    height: auto;
    justify-content: center;
    position: absolute;
    bottom: 0;
}
.Slider .home-card .img-box img {
    max-width: 100%;
    height: auto;
}
.Slider .home-card .img-box .bracelet {
    width: 100%;
    height: auto;
    padding-bottom: 3%;
}
.Slider .home-card .img-box .phone {
    width: 100%;
    height: auto;
}
.Slider .home-card .img-box .laptop {
    width: 100%;
    height: auto;
}

.carousel-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    transform: translate(-50%, -50%);
    background:  #7A07D9;
    border-radius: 30px;
    color: white;
}

.carousel-popup .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
}

.carousel-popup .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.carousel-popup .column-double {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}

.carousel-popup .header {
    margin-left: 10%;
    margin-top: 5%;
}

.carousel-popup .header h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2.5vw;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
}
#coming-soon {
    font-weight: bold;
    margin: 0 auto;
    margin-top: 20%;
    margin-bottom: 20%;
}
.carousel-popup .header h3 {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1.3vw;
}
#popup-bold {
    font-weight: bold;
    margin: 3% 0;
}

.carousel-popup .close {
    position: absolute;
    top: 4%;
    right: 3%;
    font-size: 2vw;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: none;
    color: white;
}

.carousel-popup .learn-more {
    text-align: center;
    font-size: 1vw;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    border-radius: 50px;
    background-color: white;
    color: #7A07D9;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 25%;
    padding: 0.75vw;
    margin: 5% 25%;
    display: block;
}

.carousel-popup .close:hover {
    background-color: transparent;
    box-shadow: none;
}

.carousel-popup .body{
    margin-left: 10%;
    margin-top: 5%;
}

.carousel-popup p {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 1vw;
    max-width: 90%;
}

.carousel-popup .image-div {
    width: 100%;
    height: 100%;
  position: relative;
}
.carousel-popup img {
    width: 85%;
    margin: 0 auto;
    display: block;
    position: absolute;
    bottom: 0;
}
#med-img {
    bottom: 5%;
}
.carousel-popup .mobile-med {
    display: none;
}
.carousel-popup .mobile-journals {
    display: none;
}
.carousel-popup .mobile-groups {
    display: none;
}


.home-wrapper:before {
    opacity: 0.4;
}

.home-wrapper {
    background: linear-gradient(360deg, rgba(234, 241, 255, 0) 1.26%, #780DD0 98.85%);
    /* background-image: url("/public/images/investor-short.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0%; */
    margin-top: 5%;
    color: white;
    background: white;
}
.home-wrapper .socials .row .column h1 {
    margin: 0;
    margin-top: 5%;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 3vw;
    width: 100%;
    background-image: linear-gradient(45deg, #7a07d9, #5b90f4);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home-wrapper .socials .border-social {
    margin: 0 auto;
    display: block;
    width: 50%;
}
 .home-wrapper .socials .es-header-btn .es-header-btn:active {
    background-color: #701833;
    border-radius: 20px;
}
.home-wrapper .home-section-two {
    background-image: url("/public/images/about-us-background-3.webp");
    background-size: cover;
}
.home-wrapper .home-section-saule {
    background-image: url("/public/images/saule-background-1.webp");
    background-size: cover;
    margin-top: 2%;
}
.home-wrapper .home-section-invest {
    background-image: url("/public/images/investor-background-2.webp");
    background-size: cover;
    margin-top: 2%;
    margin-bottom: 2%;
}
.home-wrapper .home-section-saule .border-saule {
    margin: 0 auto;
    display: block;
    width: 13vw;
    margin-left: 12.5%;
    /* background: url("/public/images/saule_border.png");
    background-size: contain; */
    outline: none;
    border: 0;
    text-decoration: none;
    
}
.home-wrapper h1 {
    font-size: 3.8vw;
    margin: 10% 15% 3%;
    font-family: 'Montserrat';
    text-align: center;
}
.home-wrapper .home-saule-header {
    text-align: left;
    margin-left: 12.5%;
    margin-top: 5%;
    margin-bottom: 0;
    font-weight: bold;
}
.home-wrapper h1 .word-break{
    font-weight: bolder;
    font-size: 4.0vw;
}

.home-wrapper .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    padding: 0;
}

.home-wrapper .row-special {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.home-wrapper .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.home-wrapper .column-special {
    position: relative;
}
.home-wrapper button {
    border-radius: 50px;
    background-color: white;
    color: #7830C5;
    border: none;
    text-decoration: none;
    padding: 1.2vw;
    width: 15%;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-bottom: 8%;
}

.home-wrapper .learn-more-button {
    margin-bottom: 5%;
    width: 17%;
}

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.9vw;
    margin-bottom: 11%;
}
.home-wrapper .row .column .special {
   position: relative;
   display: table;
   margin-left: 12.5%;
   margin-top: 5%;
}
.home-wrapper .row .column .special h3{
    position: relative;
    display: table-cell;
    vertical-align: bottom;
 }
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.2vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
}
.home-wrapper .home-section-invest .invest-h2 {
    margin-top: 5%;
    font-size: 3.5vw;
    margin-bottom: 3.5%;
    text-transform: uppercase;
}
.home-wrapper .home-section-invest .mobile-border-invest {
    display: none;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4vw;
    margin-bottom: 7%;
}

.home-wrapper .special-p {
    margin-top: 7.5%;
}

.home-wrapper .features {
    margin-left: 25%;
    margin-bottom: 3%;
    margin-top: 4%;
}

.home-wrapper .icon {
    width: clamp(2vw, 3.5vw, 6vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3%;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 25%;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 50%, 40vw);
    margin-left: 25%;
    margin-bottom: 15%;
}
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.4vw;
    width: 30%;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 2%;
}
.home-wrapper .join-saule-button:hover {
    background: white;
    color: #701833;
}

.home-wrapper .aversa-desc {
    width: 60%;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-bottom: 3%;
    font-size: 1.8vw;
}

.values-title {
    margin-top: 5%;
}

.values-title .growth {
    text-align: center;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 3.5vw;
    background-image: linear-gradient(89.99deg, #7A07D9 -0.96%, #007BFF 99.99%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    
}
.values-title .border-growth {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 85%;
    margin-top: 0;
}

.values-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1%;
    margin-top: 5%;
}

.values-flex .values-image-left {
    position: relative;
    width: 50%;
    margin-bottom: 2%;
    text-align: right;
}

.values-flex .values-image-right {
    position: relative;
    width: 50%;
    margin-bottom: 2%;
    text-align: left;
}

.values-flex img {
    width: 70%;
}

.values-flex .left {
   margin-right: 2%;
}
.values-flex .right {
    margin-left: 2%;
}

.values-wrapper {
    margin-top: 5%;
    color: white;
   
}
.values-wrapper .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    
}

.values-wrapper .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.values-wrapper img {
    width: 100%;
}
.bottom-banner {
    width: 100%;
    background: url("/public/images/BottomBackground-1.webp");
    background-size: cover ;
    color: white;
    margin-top: 3%;
}
.bottom-banner h1 {
    font-family: 'Montserrat';
    font-size: 3.2vw;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    margin-top: 7%;
}
.bottom-banner .border-bridge {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 85%;
    margin-top: 0;
    margin-bottom: 3%;
}
.bottom-banner p {
    margin: auto;
    text-align: center;
    max-width: 70%;
    font-family: 'Montserrat';
    margin-bottom: 3%;
    font-size: 1.7vw;
}
.bottom-banner h3 {
    text-align: center;
    margin-bottom: 7%;
    font-family: 'Montserrat';
    font-size: 2vw;
    font-weight: bold;
}

.flex-box-3 {
    width: 100%;
    height: auto;
    /* background: linear-gradient(360deg, rgba(234, 241, 255, 0) 1.26%, #780DD0 98.85%); */
    background: #780DD0;
    background-image: url("/public/images/investor-short.webp");
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    margin-top: 10%;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    overflow: visible;
}
.flex-box-3 .home-background {
    width: 100%;
    text-align: center;
    display: inline-block;
    position: relative;
}

.flex-box-3 h1 {
    font-size: 3.8vw;
    margin: 10% 300px 3%;
    font-family: 'Montserrat';
}
.flex-box-3 h1 .word-break{
    font-weight: bolder;
    font-size: 4.0vw;
}

.flex-box-3 .about-us-button {
    margin-bottom: 40px;
    border-radius: 50px;
    background-color: white;
    color: #7830C5;
    border: none;
    text-decoration: none;
    padding: 1vw;
    width: 9.5%;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
}

.flex-box-3 .flex-box-saule {
    /* display: flex;
    flex-direction: row;
    width: 100%;
    height: 5%;
    margin-top: 5%; */
    text-align: left;

}

.flex-box-3 .flex-box-saule .saule-text {
    position: absolute;
    left: 15%;
    margin-top: 7.5%;
    width: 50%;
}
.flex-box-3 .break {
    flex-basis: 100%;
    width: 0;
}

.flex-box-3 .flex-box-saule .saule-text h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.5vw;
    margin-bottom: 10%;
}
.flex-box-3 .flex-box-saule .saule-text p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2vw;
    margin-bottom: 8%;
}

.flex-box-3 .flex-box-saule .saule-text .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5%;
}

.flex-box-3 .flex-box-saule .saule-text .features-list .icon {
    
}

.flex-box-3 .flex-box-saule .saule-text .features-list .feature {
    margin: 3%;
}

.flex-box-3 .flex-box-saule .saule-screenshot {
    margin-left: 5%;
}

.aversa-pro-rect {
    width: 20%;
    height: 15px;
    background-image: linear-gradient(to right, #039fff, #8f13f7);
    margin-right: 80%;

}

.flex-box-4 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 5%;
    margin-bottom: -50px;
}

.flex-box-4 .image {
    width: 50%;
    height: auto;
}

.flex-box-4 .titles {
    display: flex;
    text-align: left;
    flex-direction: column;
    background-image: linear-gradient(to right, #dabaff, #d9ebff);
    margin-right: 30px;
    padding-bottom: -4%;


}

.flex-box-4 .titles h2 {
    font-size: 40px;
    color: #7a07d9;
    margin-top: 30px;
    font-family: 'Montserrat';
}

.flex-box-4 .text {
    color: #2a3455;

    margin: 20px 40px 10px;
    font-family: 'Montserrat';
}

.flex-box-4 .text p {
    margin-top: 38px;
    font-family: 'Montserrat';
}

.flex-box-4 .helper-break-4 {
    width: 3%;
    height: 130px;
    background-image: linear-gradient(to bottom, #8f13f7, #039fff);
    margin-right: 29px;
    margin-left: -3%;
    margin-top: 5%;
}

.flex-box-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    width: 60%;
    margin-top: 150px;
    z-index: 2;
    box-shadow: 0px 2px 15px 2px #cfcdcd;
    background-color: white;

}

.flex-box-5 .text-1 h2 {
    font-weight: bolder;
    color: #7a07d9;
    margin: 50px 80px 20px;
    font-family: 'Montserrat';
}

.flex-box-5 .text-1 h3 {
    margin: 30px 80px 20px;
    font-size: 22px;
    color: #7a07d9;
    font-family: 'Montserrat';
}

.flex-box-5 .text-2 {
    margin: 20px 75px 20px;
    font-family: 'Montserrat';

}

.button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 2%;
}

.flex-box-5 .text-2 p {

    margin-bottom: 10px;
    font-family: 'Montserrat';

}

.flex-box-5 button {
    margin-bottom: 40px;
    border-radius: 40px;
    background-image: linear-gradient(to right, #8f13f7, #5b90f4);
    color: white;
    border: none;
    text-decoration: none;
    padding: 1.5vw;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
}


.flex-box-6 {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-image: linear-gradient(to right, #7a07d99b 0%, #5184fc69 100%), url("working.webp");

    background-size: cover;

    margin-top: -4%;

}

.flex-box-6 .helper-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-evenly;
    margin: 50px 80px 40px;
}

.flex-box-6 .text-1 {

    width: 50%;

    margin: 70px 50px 40px;
}

.flex-box-6 .text-1 h1 {
    font-weight: bolder;
    font-size: 5vw;
    color: white;
    font-family: 'Montserrat';

}

.flex-box-6 .break {
    width: 20px;
    height: 28vw;
    background-image: linear-gradient(to bottom, #ffffff, #039fff);
    margin: 80px 40px 40px;
}

.flex-box-6 .text-2 {
    color: white;
    font-size: 1.5vw;
    text-align: left;
    margin: 80px 25px 20px;
}

.flex-box-6 .text-2 p {
    font-family: 'Montserrat';
    font-weight: lighter;
}


.flex-box-7 {
    display: flex;
    align-items: center;
    flex-direction: column;

    background-image: linear-gradient(to bottom right, #e11afb43 0%, #96bdfc72 85%), url("reading.webp");
    background-size: cover;

}

.flex-box-7 .text-1 {
    text-align: center;
    margin: 90px 40px 80px;
    width: 65%;
    font-family: 'Montserrat';
    color: #7a07d9;
}

.flex-box-7 .break {
    width: 45%;
    height: 5px;
    background-image: linear-gradient(to right, #8f0bfc, #4078f5);
}

.flex-box-7 .fun-text {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
    flex-wrap: wrap;
    vertical-align: top;
    justify-content: space-evenly;

}

.flex-box-7 .fun-text h1 {
    color: #7a07d9;
    font-family: 'Montserrat';
    font-size: 30px;
    font-weight: bolder;
}

.flex-box-7 .fun-text p {
    display: flex;
    text-align: center;
    font-family: 'Montserrat';
}


#three {
    margin-top: 1%;
}

.fun-text .text-2 {
    display: flex;
    align-items: center;
    float: left;

    width: 15%;
    flex-direction: column;
    margin: 30px 20px 500px;
}


.flex-box-8 {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin-top: -32%;
    margin-bottom: 5%;
    background-color: white;
    box-shadow: 0px 2px 15px 2px #cfcdcd;

}

.flex-box-8 .text-1 {

    width: 60%;
    text-align: center;
    font-family: 'Montserrat';
}

.flex-box-8 h1 {
    margin: 75px 100px 45px;
    color: #7a07d9;
    font-size: 2.3vw;
}

.flex-box-8 p {
    font-family: 'Montserrat';
    font-size: 1.2vw;
    margin-bottom: 40px;
}

.flex-box-8 .aversa-pro-rect {
    margin-left: 160%;


    height: 5px;


}

.popup-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.popup-form h1 {
    color: #7a07d9;
    font-family: 'Montserrat';
    font-size: 1.6vw;
    margin: 10% 10% 2%;
}

.popup-form p {
    color: #2a3455;
    font-size: 1.4vw;
    margin: 0 30% 5%;
}

.popup-form .form-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;

}

form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}


.popup-form .form-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin: 4% 5% 2%;
    color: #114893;
    width: 40%;
}

.popup-form .form-box input {
    width: 100%;
}

.form-flex {
    margin-bottom: 4%;
}

.popup-form .close-popup {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2%;
    margin-right: 2%;
}

.popup-form .submit-container {
    width: 100%;
    height: auto;
    align-items: center;
}

.popup-form .submit-button {
    margin: 20% 10px 10% 0%;
    border-radius: 40px;
    color: white;
    border: none;
    text-decoration: none;
    padding-left: 3vw;
    padding-right: 3vw;

    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
}

.popup-form .submit-button:not([disabled]) {
    background: linear-gradient(to right, #8f13f7, #5b90f4);
}

@media screen and (max-width: 1450px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 9%;
    }
    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 17%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 20%;
        height: auto;
        padding-bottom: 1.5%;
    }
    .Slider .home-card .img-box .laptop {
        width: 26%;
        height: auto;
    }

    .carousel-popup .header h1 {
        font-size: 2.6vw;
    }
    .carousel-popup .header h3 {
        font-size: 1.4vw;
    }
    .carousel-popup p {
        font-size: 1.2vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.2vw;
        padding: 1vw;
    }
    .carousel-popup .close {
        position: absolute;
        top: 4%;
        right: 2%;
        font-size: 2.5vw;
        background-repeat: no-repeat;
        cursor: pointer;
        box-shadow: none;
        color: white;
    }
}
@media screen and (max-width: 1207px) {
    .Slider .home-card{
        height: clamp(200px, 33vw, 600px);
    }

    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 13%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 16%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 22%;
        height: auto;
    }

    .Slider {
        width: 93%;
    }

    .Slider .home-card h1 {
        font-weight: bolder;
        font-size: 2vw;
        margin: 2% 5% 2%;
    }
    .Slider .home-card p{
        font-size: 1.25vw;
    }

    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 20%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 24%;
        height: auto;
        padding-bottom: 1%;
    }
    .Slider .home-card .img-box .laptop {
        width: 29%;
        height: auto;
    }

    .carousel-popup .header h1 {
        font-size: 2.8vw;
    }
    .carousel-popup .header h3 {
        font-size: 1.6vw;
    }
    .carousel-popup p {
        font-size: 1.4vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.4vw;
        padding: 1.1vw;
        width: 30%;
    }
    .carousel-popup .close {
        position: absolute;
        top: 4%;
        right: 2%;
        font-size: 3vw;
        background-repeat: no-repeat;
        cursor: pointer;
        box-shadow: none;
        color: white;
    }
    .flex-box-2 .home-main-img img {
        display: none;
    }

    .flex-box-2 .home-main-img-mobile img {
        display: block; 
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
    }

    .home-flex-box .flex-box-2 .text {
        margin-top: 10%;
    }

    .home-flex-box .flex-box-2 .text h1 {

        font-size: 5vw;
    }

    .flex-box-2 .home-main-img {
        margin-top: 60px;
    }

    .flex-box-3 {
        margin-top: 15%;
    }

    .flex-box-3 .aversa-pro h2 {
        font-size: 1.2rem;
    }

    .aversa-pro-rect {
        width: 30%;
        height: 5px;
        margin-right: 70%;

    }

    .flex-box-4 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 5%;
    }

    .flex-box-4 .image {
        width: 100%;
        height: auto;
        margin-bottom: -2%;
    }

    .flex-box-4 .image img {
        width: 100%;
        height: auto;

    }

    .flex-box-4 .titles {
        display: flex;
        width: 100%;
        text-align: left;
        flex-direction: column;
        background-image: linear-gradient(to right, #dabaff, #d9ebff);
        margin-right: -30px;

    }

    .flex-box-4 .text {
        font-size: 2.8vw;
        margin: 40px 50px 20px 40px;
    }

    .flex-box-4 .text p {
        margin-top: 20px;
        font-family: 'Montserrat';
    }

    .flex-box-4 .titles h2 {
        font-size: 5vw;


    }

    .flex-box-5 {

        margin-top: 10px;

        width: 90%;
    }

    .flex-box-5 .text-1 h3 {
        margin: 30px 20px 20px;
        font-size: 3.8vw;
        color: #7a07d9;
    }

    .flex-box-5 .text-2 {
        margin: 10px 20px 20px;
        font-size: 2.6vw;

    }

    .flex-box-6 {
        display: flex;
        flex-direction: column;
        background-image: none;
        width: 100%;

    }

    .flex-box-6 .helper-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-content: space-evenly;
        margin: 0px 0px 0px;
        width: 100%;
    }

    .flex-box-6 .text-1 {

        background-image: linear-gradient(to right, #7a07d9ed 0%, #5184fcc4 100%), url("workingmobile.webp");
        background-size: cover;
        width: 100%;
        height: auto;
        color: white;
        font-weight: bolder;
        font-family: 'Montserrat';
        margin: 0px 0px 0px;

    }

    .flex-box-6 .text-1 h1 {
        font-weight: bolder;
        margin: 40px 30px 40px;
        font-size: 13vw;
    }


    .flex-box-6 .break {
        display: none;
    }

    .flex-box-6 .text-2 {

        height: auto;
        color: white;
        background-color: #3D2488;
        font-size: 3vw;
        margin: 0px 0px 0px;
        width: 100%;
    }

    .flex-box-6 .text-2 p {
        margin: 50px 55px 50px;
        font-family: 'Montserrat';
    }

    .flex-box-7 .text-1 {

        width: 75%;

    }

    .flex-box-7 .fun-text h1 {
        color: #7a07d9;
        font-family: 'Montserrat';
        font-size: 4vw;
    }

    .flex-box-7 .fun-text p {
        display: flex;
        text-align: center;

        font-size: 3vw;
    }

    .flex-box-7 .fun-text {
        margin-bottom: 400px;
    }

    .flex-box-7 .fun-text .text-2 {
        margin: 20px 20px 20px;
        width: 29%;
    }

    #two {
        margin-top: 3%;
    }

    #four {
        margin-top: 2%;
    }

    .flex-box-8 {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;
        margin-top: -50%;
        background-color: white;
        box-shadow: 0px 2px 15px 2px #cfcdcd;

    }

    .flex-box-8 .text-1 {

        width: 60%;
        text-align: center;
        font-family: 'Montserrat';
    }

    .flex-box-8 h1 {
        margin: 35px -10px 30px;
        color: #7a07d9;
        font-size: 6vw;
    }

    .flex-box-8 p {
        font-family: 'Montserrat';
        font-size: 3vw;
        margin: 10px -20px 20px;
    }

    .flex-box-8 .aversa-pro-rect {
        width: 25%;
        height: 5px;
        margin-right: 85%;

    }

    .popup-form h1 {

        font-family: 'Montserrat';
        font-size: 5vw;
        margin: 10% 10% 3%;
    }

    .popup-form p {
        font-size: 2.3vw;
        margin: 0 20% 5%;
    }

    .popup-form .form-box {
        margin: 5% 0 5%;
        width: 100%;
    }

    .popup-form .form-flex {
        display: flex;
        flex-direction: column;

    }

    .popup-form .submit-button {
        margin: 20% 5% 10%;
        margin-right: 30px;
        border-radius: 40px;
        color: white;
        border: none;
        text-decoration: none;
        padding-left: 3vw;
        padding-right: 3vw;

        cursor: pointer;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
    }
}

@media screen and (max-width: 1100px) {
    
    .Slider .home-card p{
        font-size: 1.6vw;
    }
    .carousel-popup {
        width: 80%;
    }
    .carousel-popup .header h1 {
        font-size: 2.95vw;
    }
    .carousel-popup .header h3 {
        font-size: 1.75vw;
    }
    .carousel-popup p {
        font-size: 1.55vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.55vw;
        padding: 1.2vw;
        width: 30%;
    }
}

@media screen and (max-width: 1000px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 15%;
    }

    .carousel-popup .header {
        margin-left: 10%;
        margin-top: 7.5%;
    }
    .carousel-popup .header h1 {
        font-size: 2.95vw;
    }
    .carousel-popup .header h3 {
        font-size: 1.75vw;
    }
    .carousel-popup p {
        font-size: 1.55vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.55vw;
        padding: 1.35vw;
        width: 33%;
    }

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.9vw;
    margin-bottom: 7%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.2vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    margin-bottom: 4%;
}

.home-wrapper .features {
    margin-left: 25%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(2vw, 4vw, 6vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 25%;
}
/* .home-wrapper .aversa-screenshot {
    width: 45%;
    margin-left: 25%;
    margin-bottom: 15%;
} */
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.5vw;
    width: 32%;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 6%;
}
.home-wrapper .aversa-desc {
    font-size: 1.9vw;
}
}

@media screen and (max-width: 900px) {
    
.Slider .home-card h1 {
    font-weight: bolder;
    font-size: 2vw;
    margin: 1.5% 5% 1.5%;
}
.Slider .home-card p{
    font-size: 1.55vw;
}

.carousel-popup {
    width: 85%;
}
.carousel-popup .header {
    margin-left: 10%;
    margin-top: 7.5%;
}
.carousel-popup .header h1 {
    font-size: 3.15vw;
}
.carousel-popup .header h3 {
    font-size: 1.95vw;
}
.carousel-popup p {
    font-size: 1.75vw;
}
.carousel-popup .learn-more {
    font-size: 1.75vw;
    padding: 1.4vw;
    width: 33%;
}
    
.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.1vw;
    margin-bottom: 7%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.75vw;
    margin-bottom: 8%;
}

.home-wrapper .features {
    margin-left: 25%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(2vw, 4vw, 6vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 25%;
}
/* .home-wrapper .aversa-screenshot {
    width: 45%;
    margin-left: 25%;
    margin-bottom: 15%;
} */
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.65vw;
    width: 32%;
    font-size: 1.65vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 3%;
}
.home-wrapper .aversa-desc {
    font-size: 2.15vw;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 55%, 40vw);
    margin-left: 25%;
    margin-bottom: 15%;
}
}

@media screen and (max-width: 800px) {

    .Slider {
        width: 90%;
    }

    .Slider .home-card h1 {
        font-weight: bolder;
        font-size: 2.5vw;
        margin: 1.5% 5% 1.5%;
    }
    .Slider .home-card p{
        font-size: 1.75vw;
    }

    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 13%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 16%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 22%;
        height: auto;
    }

    .carousel-popup .header {
        margin-left: 10%;
        margin-top: 7.5%;
    }
    .carousel-popup .header h1 {
        font-size: 3.25vw;
    }
    .carousel-popup .header h3 {
        font-size: 2.05vw;
    }
    .carousel-popup p {
        font-size: 1.85vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.85vw;
        padding: 1.5vw;
        width: 33%;
    }
    .carousel-popup .close {
        position: absolute;
        top: 4%;
        right: 1.5%;
        font-size: 3.5vw;
        background-repeat: no-repeat;
        cursor: pointer;
        box-shadow: none;
        color: white;
    }

    .home-wrapper button {
        border-radius: 50px;
        background-color: white;
        color: #7830C5;
        border: none;
        text-decoration: none;
        padding: 1.5vw;
        width: 28%;
        font-size: 2vw;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
        margin: 0 auto;
        display: block;
        margin-bottom: 7%;
    }
    .home-wrapper .learn-more-button {
        padding: 1.6vw;
        width: 23%;
    }

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4vw;
    margin-bottom: 10%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.8vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.2vw;
    margin-bottom: 8%;
}

.home-wrapper .features {
    margin-left: 25%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(2vw, 4vw, 6vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 25%;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 60%, 40vw);
    margin-left: 25%;
    margin-bottom: 15%;
}
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.8vw;
    width: 38%;
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 6%;
}
.home-wrapper .aversa-desc {
    font-size: 2.4vw;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 63%, 40vw);
    margin-left: 25%;
    margin-bottom: 15%;
}
.bottom-banner p {
    margin: auto;
    text-align: center;
    max-width: 70%;
    font-family: 'Montserrat';
    margin-bottom: 3%;
    font-size: 2vw;
}
.bottom-banner h3 {
    text-align: center;
    margin-bottom: 7%;
    font-family: 'Montserrat';
    font-size: 2.3vw;
    font-weight: bold;
}
}


@media screen and (max-width: 700px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 20%;
    }
    .carousel-popup .header {
        margin-left: 10%;
        margin-top: 7.5%;
    }
    .carousel-popup .header h1 {
        font-size: 3.35vw;
    }
    .carousel-popup .header h3 {
        font-size: 2.15vw;
    }
    .carousel-popup p {
        font-size: 1.95vw;
    }
    .carousel-popup .learn-more {
        font-size: 1.95vw;
        padding: 1.5vw;
        width: 33%;
    }


.home-wrapper button {
    border-radius: 50px;
    background-color: white;
    color: #7830C5;
    border: none;
    text-decoration: none;
    padding: 1.3vw;
    width: 18.5%;
    font-size: 1.9vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-bottom: 7%;
    }

.home-wrapper .learn-more-button {
    font-size: 1.8vw;
}

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 2.5vw;
    margin-bottom: 10%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.7vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 2.1vw;
    margin-bottom: 8%;
}

.home-wrapper .features {
    margin-left: 25%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(3vw, 5vw, 7vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 25%;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 60%, 40vw);
    margin-left: 25%;
    margin-bottom: 15%;
}
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.8vw;
    width: 39%;
    font-size: 2vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 5%;
}
.home-wrapper .aversa-desc {
    font-size: 2.6vw;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 69%, 40vw);
    margin-left: 25%;
    margin-bottom: 25%;
}
.values-flex img {
    width: 75%;
}

}

@media screen and (max-width: 600px) {

    .carousel-popup {
        width: 95%;
    }

    .carousel-popup .header {
        margin-left: 10%;
        margin-top: 7.5%;
    }
    .carousel-popup .header h1 {
        font-size: 3.5vw;
    }
    .carousel-popup .header h3 {
        font-size: 2.3vw;
    }
    .carousel-popup p {
        font-size: 2.1vw;
    }
    .carousel-popup .learn-more {
        font-size: 2.1vw;
        padding: 1.6vw;
        width: 33%;
    }

    .carousel-popup img {
        width: 95%;
    }

    .Slider .home-card h1 {
        font-weight: bolder;
        font-size: 2.75vw;
        margin: 1% 5% 1%;
    }
    .Slider .home-card p{
        font-size: 2.15vw;
    }

    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 14%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 17%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 24%;
        height: auto;
    }
    .flex-box-8 {
        margin-top: -65%;
    }

    .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 2%;
    }

    .flex-box-7 .fun-text {
        margin-bottom: 350px;
    }

    .flex-box-8 .aversa-pro-rect {
        width: 30%;
        height: 5px;
        margin-right: 90%;

    }

    .home-wrapper h1 {
        font-size: 5.3vw;
        font-family: 'Montserrat';
        text-align: center;
        width: 90%;
        margin: 10% auto 5%;
        display: block;
    }
    .home-wrapper h1 .word-break{
        font-weight: bolder;
        font-size: 6.3vw;
    }

    .home-wrapper button {
        border-radius: 50px;
        background-color: white;
        color: #7830C5;
        border: none;
        text-decoration: none;
        padding: 2.2vw;
        width: 28%;
        font-size: 3vw;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
        margin: 0 auto;
        display: block;
        margin-bottom: 10%;
    }

.home-wrapper .learn-more-button {
    font-size: 2.6vw;
    padding: 2vw;
    width: 28%;
    }

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.3vw;
    margin-bottom: 10%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 4.5vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
    margin-top: 5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 3.3vw;
    margin-bottom: 8%;
}

.home-wrapper .features {
    margin-left: 10%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(4vw, 6vw, 8vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 10%;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 85%, 40vw);
    margin-left: 15%;
    margin-bottom: 15%;
    margin-top: 9%;
}
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 2.5vw;
    width: 60%;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 4%;
    margin-bottom: 25%;
}
.home-wrapper .aversa-desc {
    width: 80%;
    font-size: 3.3vw;
    margin-bottom: 5%;
}
.home-wrapper .home-section-invest .invest-h2 {
    font-size: 4vw;
    margin-bottom: 0;
}
.home-wrapper .home-section-invest .mobile-border-invest {
    margin: 0 auto;
    margin-top: 0;
    display: block;
    width: 70%;
    margin-bottom: 2%;
}
.home-wrapper .home-saule-header {
    text-align: center;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 6vw;
}
.home-wrapper .home-section-saule .border-saule {
    margin: 0 auto;
    display: block;
    width: 23vw;
    outline: none;
    border: 0;
    text-decoration: none;
} 
.home-wrapper .home-section-saule .row .column .features .special-p {
    margin-left: unset;
    margin-right: 25%;
}

.home-wrapper .row .column .special {
    margin-left: 0;
    text-align: center;
 }
 .home-wrapper .row .column .special h3{
     font-size: 4vw;
  }

  .home-wrapper .row .column .features .special-p{
    margin-left: 0;
    text-align: center;
 }

 .values-flex img {
    width: 90%;
}
.values-title .growth {
    text-align: center;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 5.5vw;
    background-image: linear-gradient(89.99deg, #7A07D9 -0.96%, #007BFF 99.99%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    
}
.values-title .border-growth {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 70%;
    margin-top: 0;
}
.bottom-banner h1 {
    font-family: 'Montserrat';
    font-size: 5vw;
    font-weight: bold;
    text-align: center;
    margin: auto;
    margin-top: 7%;
    max-width: 90%;
}
.bottom-banner .border-bridge {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 75%;
    margin-top: 0;
    margin-bottom: 3%;
}
.bottom-banner p {
    margin: auto;
    text-align: center;
    max-width: 70%;
    font-family: 'Montserrat';
    margin-bottom: 3%;
    font-size: 2.9vw;
}
.bottom-banner h3 {
    text-align: center;
    margin-bottom: 7%;
    font-family: 'Montserrat';
    font-size: 3.3vw;
    font-weight: bold;
}
}

@media screen and (max-width: 550px) {
.home-wrapper .home-saule-header {
    text-align: center;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 6vw;
}
.home-wrapper .home-section-saule .border-saule {
    margin: 0 auto;
    display: block;
    width: 23vw;
    outline: none;
    border: 0;
    text-decoration: none;
} 
.home-wrapper .home-section-saule .row .column .features .special-p {
    margin-left: unset;
    margin-right: 25%;
}
.Slider .home-card h1 {
    font-weight: bolder;
    font-size: 2.6vw;
    margin: 1% 5% 1%;
}
.Slider .home-card p{
    font-size: 2.1vw;
} 

.carousel-popup {
    max-height: 95%;
    height: auto;
    max-width: 100%;
}

.carousel-popup .header {
    margin-left: 10%;
    margin-top: 5%;
}
.carousel-popup .row {
    flex-flow: column wrap;

}
.carousel-popup .column-double {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}
.carousel-popup .body{
    margin-left: 10%;
    margin-top: 3%;
    margin-right: 10%;
}
.carousel-popup .header h1 {
    font-size: 5vw;
}
.carousel-popup .header h3 {
    font-size: 3.5vw;
}
#coming-soon {
    font-weight: bold;
    margin: 0 auto;
    margin-top: 10%;
    margin-bottom: 20%;
}
.carousel-popup p {
    font-size: 3.2vw;
}
.carousel-popup .learn-more {
    font-size: 3.3vw;
    padding: 2.5vw;
    width: 50%;
}

.carousel-popup .img-box {
    max-height: 100%;
    display: inline-block;
    overflow-y: hidden;
}
.carousel-popup .image-div {
    display: none;
}
.carousel-popup .mobile-groups {
    display: unset;
}
.carousel-popup .mobile-med {
    display: unset;
}
.carousel-popup .mobile-journals {
    display: unset;
}
.carousel-popup img {
    width: 60%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: 15%; 
    position: unset;
    top: unset;
    left: unset;
    transform: unset;
    margin: auto;
    margin-top: 5%;
}
.carousel-popup .close {
    position: absolute;
    top: 3%;
    right: 1%;
    font-size: 6vw;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: none;
    color: white;
}

}

@media screen and (max-width: 480px) {

    .home-flex-box .flex-box-2 .text {
        margin-top: 25%;
    }

    .Slider{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 85%;
        text-align: center;
        height: auto;
        margin-top: 5%;
    }

    .Slider .home-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 2px 15px 1px #cfcdcd8a;
        color: white;
        text-align: left;
        background-image: linear-gradient(#007BFF, white);
        border-radius: 12%;
        width: 75%;
        height: clamp(200px, 50vw, 600px);
        margin-left: 13%;
    }
    .Slider .home-card h1 {
        font-weight: bolder;
        font-size: 4.1vw;
        margin: 1% 5% 1%;
    }
    .Slider .home-card p{
        font-size: 3.3vw;
    }

    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 12%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 14%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 18%;
        height: auto;
    }

    
  
    .home-wrapper h1 {
        font-size: 5.3vw;
        font-family: 'Montserrat';
        text-align: center;
        width: 90%;
        margin: 10% auto 5%;
        display: block;
    }
    .home-wrapper h1 .word-break{
        font-weight: bolder;
        font-size: 6.3vw;
    }
    .home-wrapper .about-us-button {
        border-radius: 50px;
        background-color: white;
        color: #7830C5;
        border: none;
        text-decoration: none;
        padding: 3vw;
        width: 33%;
        font-size: 3.3vw;
        font-style: normal;
        font-weight: 700;
        cursor: pointer;
        transition-duration: 0.2s;
        font-family: 'Montserrat';
        margin: 0 auto;
        display: block;
        margin-bottom: 10%;
    }

.home-wrapper h3 {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 3.8vw;
    margin-bottom: 10%;
}
.home-wrapper h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 4.8vw;
    line-height: 51px;
    margin: 0 auto;
    display: block;
    margin-bottom: 2.5%;
    margin-top: 5%;
}
.home-wrapper p {
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 3.7vw;
    margin-bottom: 8%;
}

.home-wrapper .features {
    margin-left: 7%;
    margin-bottom: 3%;
}

.home-wrapper .icon {
    width: clamp(5vw, 7vw, 9vw);
}

.home-wrapper .features-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-wrapper .features-list .feature {
    margin: 3%;
}

.home-wrapper .other-features {
    margin-top: 4%;
    margin-left: 10%;
}
.home-wrapper .aversa-screenshot {
    width: clamp(20vw, 85%, 50vw);
    margin-left: 10%;
    margin-bottom: 15%;
    margin-top: 9%;
}
.home-wrapper .join-saule-button {
    border-radius: 50px;
    background: #E33637;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 3vw;
    width: 67%;
    font-size: 3vw;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    transition-duration: 0.2s;
    font-family: 'Montserrat';
    margin: 0 auto;
    display: block;
    margin-top: 4%;
    margin-bottom: 25%;
}
.home-wrapper .aversa-desc {
    width: 90%;
    font-size: 4vw;
    margin-bottom: 5%;
}

.home-wrapper .row .column .special {
    margin-left: 0;
    text-align: center;
 }
 .home-wrapper .row .column .special h3{
     font-size: 4.3vw;
  }

  .home-wrapper .row .column .features .special-p{
    margin-left: 0;
    text-align: center;
 }


}
@media screen and (max-width: 450px) {
    .bottom-banner h1 {
        font-family: 'Montserrat';
        font-size: 5vw;
        font-weight: bold;
        text-align: center;
        margin: auto;
        margin-top: 7%;
        max-width: 90%;
    }
    .bottom-banner .border-bridge {
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 75%;
        margin-top: 0;
        margin-bottom: 3%;
    }
    .bottom-banner p {
        margin: auto;
        text-align: center;
        max-width: 70%;
        font-family: 'Montserrat';
        margin-bottom: 4%;
        font-size: 3.3vw;
    }
    .bottom-banner h3 {
        text-align: center;
        margin-bottom: 7%;
        font-family: 'Montserrat';
        font-size: 3.4vw;
        font-weight: bold;
    }
}
@media screen and (max-width: 400px) {
    .carousel-popup .header h1 {
        font-size: 5.2vw;
    }
    .carousel-popup .header h3 {
        font-size: 3.8vw;
    }
    .carousel-popup p {
        font-size: 3.6vw;
    }
}
@media screen and (max-width: 390px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 29%;
    }
    .Slider .home-card h1 {
        font-weight: bolder;
        font-size: 4.25vw;
        margin: 1% 5% 1%;
    }
    .Slider .home-card p{
        font-size: 3.5vw;
    }
    
}
@media screen and (max-width: 370px) {
    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 14%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 16%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 20%;
        height: auto;
    }
}

@media screen and (max-width: 350px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 32%;
    }
    .Slider .home-card .img-box{
        width: 20%;
    }
    .Slider .home-card .img-box .phone {
        width: 16%;
        height: auto;
    }
    .Slider .home-card .img-box .bracelet {
        width: 18%;
        height: auto;
    }
    .Slider .home-card .img-box .laptop {
        width: 22%;
        height: auto;
    }
    /* .carousel-popup .header h1 {
        font-size: 5.6vw;
    }
    .carousel-popup .header h3 {
        font-size: 4.3vw;
    }
    .carousel-popup p {
        font-size: 4.1vw;
    }
    .carousel-popup .learn-more {
        font-size: 4.3vw;
        padding: 2.75vw;
        width: 55%;
        margin: auto;
    }
    .carousel-popup img {
        width: 70%;
        margin-top: 7.5%;
    } */
    .bottom-banner h1 {
        font-family: 'Montserrat';
        font-size: 5vw;
        font-weight: bold;
        text-align: center;
        margin: auto;
        margin-top: 7%;
        max-width: 90%;
    }
    .bottom-banner .border-bridge {
        text-align: center;
        margin: 0 auto;
        display: block;
        width: 75%;
        margin-top: 0;
        margin-bottom: 3%;
    }
    .bottom-banner p {
        margin: auto;
        text-align: center;
        max-width: 70%;
        font-family: 'Montserrat';
        margin-bottom: 4%;
        font-size: 3.65vw;
    }
    .bottom-banner h3 {
        text-align: center;
        margin-bottom: 7%;
        font-family: 'Montserrat';
        font-size: 3.9vw;
        font-weight: bold;
    }
}
@media screen and (max-width: 300px) {
    .home-flex-box .flex-box-2 .text {
        margin-top: 36%;
    }
}

@media screen and (min-width: 1020px) and (max-width: 1030px) {
    .flex-box-8 {
        margin-top: -40%;
    }


}


@media screen and (min-width: 1600px) {
    .flex-box-4 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        margin-top: 5%;
        margin-bottom: -50px;
    }

    .flex-box-4 .image {
        width: 50%;
        height: auto;
    }

    .flex-box-4 .titles {
        display: flex;
        text-align: left;
        flex-direction: column;
        background-image: linear-gradient(to right, #dabaff, #d9ebff);
        margin-right: 5%;
        padding-bottom: -4%;


    }

    .flex-box-4 .titles h2 {
        font-size: 40px;
        color: #7a07d9;
        margin-top: 30px;
        font-family: 'Montserrat';
    }

    .flex-box-4 .text {
        color: #2a3455;

        margin: 20px 40px 10px;
        font-family: 'Montserrat';
    }

    .flex-box-4 .text p {
        margin-top: 38px;
        font-family: 'Montserrat';
    }

    .flex-box-4 .helper-break-4 {
        width: 2.6%;
        height: 160px;
        background-image: linear-gradient(to bottom, #8f13f7, #039fff);
        margin-right: 5%;
        margin-left: -6%;

        margin-top: 5%;
    }

    .flex-box-8 {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 80%;
        margin-top: -24%;
        background-color: white;
        box-shadow: 0px 2px 15px 2px #cfcdcd;

    }

    #one {
        margin-top: 1%
    }

    #three {
        margin-top: 1%;
    }


} 