*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  transform: scale(0.7);
  opacity: 0.5;
  padding-bottom: 2%;
  cursor: pointer;
 background-color: transparent;
  border: none;
  z-index: 20;
}


@media screen and (max-width: 800px) {

  .back-to-top {
    position: fixed;
  bottom:-20px;
  right: -20px;
    transform: scale(0.4);
    
  }
}