.navbar {
  box-shadow: 0px 2px 25px 2px #cfcdcd;
  background: linear-gradient(90deg, #7A07D9 16.67%, #007BFF 100%);
  height: 80px;
  display: flex;
  width: 100%;
  
  font-size: 1rem;
  
  position: fixed;
  top: 0;
 z-index: 5;
 
}

.navbar-logo {
  margin-bottom: 1%;
  cursor: pointer;
  
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 35%;
  
  margin-left: 0px 0px 5px;


  justify-self:flex-end;
}
.navbar button{
  text-decoration: none;
  background-color: transparent;
  border: none

}

.navbar button:hover, button:focus{
  border: none;
  text-decoration: none;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

}
.nav-item {
  display: flex;
  align-items: center;
  height: 70px;
  
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  color: white;
  font-style: normal;
}

.nav-links:hover {
    color: rgb(17, 17, 17);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}


.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
@media screen and (max-width: 1207px) and (orientation: landscape) {
  
  .nav-menu.active {
    background: #faf6f6;
    width: 100%;
    height: auto;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
   
  }
  

 
}
@media screen and (max-width: 1207px)  {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    position: absolute;
    top: 80px;
    left: -100%; 
    border: 1px black;
    opacity: 1;
    
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #faf6f6;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
   
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px black;
    color: #2a3455d6;
  }

  .nav-links:hover {
    background-color: #7a07d9;
    border-radius: 0;
    color: white;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 90%);
  }
  .navbar-logo .logo-nav {
    max-width: 80%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    margin-top: -0.9%;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: flex;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }
}
@media screen and (max-width: 700px) {
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 95%);
  }
}
@media screen and (max-width: 450px) {
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 100%);
  }
  .navbar-logo .logo-nav {
    max-width: 70%;
  }
}
@media screen and (max-width: 350px) {
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10%, 100%);
  }
  .navbar-logo .logo-nav {
    max-width: 60%;
  }
}